<template>
  <UiEmptyState
    :image-src="require('@/assets/img/something_went_wrong_error.svg')"
    :title-text="$t('Oops, something went wrong')"
  >
    <template #description>
      <p v-if="supportUrl">
        {{ $t('We are unable to start the return request. Please, contact our shop for support.') }}
      </p>
      <p v-else>
        {{ $t('We are unable to start the return request. Please, try again. If it persists contact us.') }}
      </p>
    </template>
    <template #actions>
      <UiButton
        v-if="supportUrl"
        mode="primary"
        class="main-button"
      >
        <a
          class="main-button__btn"
          :style="brandStyle"
          :href="supportUrl"
        >{{ $t('Support') }}</a>
      </UiButton>
      <UiButton
        v-else-if="brandWebsiteUrl"
        mode="primary"
        class="main-button"
      >
        <a
          class="main-button__btn"
          :style="brandStyle"
          :href="brandWebsiteUrl"
        >{{ $t('Support') }}</a>
      </UiButton>
      <UiButton
        v-else
        mode="primary"
        class="main-button"
      >
        <router-link
          class="main-button__btn"
          :style="brandStyle"
          :to="{ name: 'home' }"
        >{{ $t('Try again') }}</router-link>
      </UiButton>
    </template>
  </UiEmptyState>
</template>

<script>
import { brandColourMixin, allUrls } from '@/components/mixins'
import { UiButton, UiEmptyState } from '@sendcloud/ui-library'

export default {
  name: 'CommonError',
  components: {
    UiButton,
    UiEmptyState,
  },
  mixins: [brandColourMixin, allUrls],
}
</script>
